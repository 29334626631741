.form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 24px 1rem;
}

.divider {
    height: 25px;
    width: 2px;
    background-color: var(--grey-400);
    margin: 0 0.5rem;
}

.autoSendSelect {
    background-color: white;
    border: 1px solid var(--primary-500);
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
}

.allowlistItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 16px 24px;
    min-width: max-content;
}

.form.allowlistItem {
    align-items: flex-start;
    min-width: auto;
}

.allowlistItem:nth-child(odd) {
    background-color: white;
}

.allowlistItem:last-child {
    border-bottom: none;
}

.item {
    /* flex: 1; */
    min-width: 10.5rem;
    width: 10.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
}

.item.shrinkWidth {
    min-width: 6rem;
    width: 6rem;
}

.item.sender {
    min-width: 15rem;
    width: 15rem;
}

.item label {
    color: var(--grey-500);
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
}

.item span {
    display: block;
    color: var(--grey-800);
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.editableCell {
    overflow: visible;
}

.editableCellInput {
    margin-top: 0.375rem;
    height: 2.5rem;
}

.editableCellInput label {
    top: 0.625rem;
}

.editableCellInput input {
    padding-right: 0.75rem;
}
